// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Page.css */

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: transform 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Page.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,+BAA+B;AACjC","sourcesContent":["/* Page.css */\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  transition: transform 0.3s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
